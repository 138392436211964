export const drukM = [
  {
    id: 1,
    label: "szt.",
    format: "Format",
    grupa_1_20: "1-10",
    grupa_21_50: "11-50",
    grupa_51_100: "51-100",
    grupa_101_300: "101-300",
    grupa_300: ">300",
  },
  {
    id: 2,
    format: "A4 Czb",
    grupa_1_20: "0,45 zł",
    grupa_21_50: "0,40zł",
    grupa_51_100: "0,35 zł",
    grupa_101_300: "0,25 zł.",
    grupa_300: "0,20 zł",
  },
  {
    id: 3,
    format: "A4 kolor",
    grupa_1_20: "2,00 zł",
    grupa_21_50: "1,90 zł",
    grupa_51_100: "1,80 zł",
    grupa_101_300: "1,70 zł",
    grupa_300: "1,60 zł",
  },
  {
    id: 4,
    format: "A3 Czb",
    grupa_1_20: "0,80 zł",
    grupa_21_50: "0,70 zł",
    grupa_51_100: "0,65 zł",
    grupa_101_300: "0,60 zł.",
    grupa_300: "0,50 zł",
  },
  {
    id: 5,
    format: "A3 kolor",
    grupa_1_20: "3,20 zł",
    grupa_21_50: "3,10 zł",
    grupa_51_100: "3,00 zł",
    grupa_101_300: "2,90 zł",
    grupa_300: "2,80 zł",
  },
]

export const drukW = [
  {
    id: 1,
    zadruk: "stopień zadruku",
    a2: "A2",
    a1: "a1",
    a0: "a0",
    b2: "b2",
    b1: "b1",
    a2label: "420x594 mm",
    a1label: "594x841 mm",
    a0label: "841x1189 mm",
    b2label: "500x700 mm",
    b1label: "700x1000 mm",
  },

  {
    id: 2,
    zadruk: "<30%",
    a2: "4 zł",
    a1: "8 zł",
    a0: "16 zł",
    b2: "8 zł",
    b1: "16 zł",
  },

  {
    id: 3,
    zadruk: "30-70 %",
    a2: "6 zł",
    a1: "12 zł",
    a0: "23 zł",
    b2: "10 zł",
    b1: "21 zł",
  },

  {
    id: 4,
    zadruk: ">70 %",
    a2: "8 zł",
    a1: "16 zł",
    a0: "31 zł",
    b2: "13 zł",
    b1: "26 zł",
  },

  {
    id: 5,
    zadruk: "składanie do formatu A4",
    a2: "1 zł",
    a1: "2 zł",
    a0: "4 zł",
    b2: "2 zł",
    b1: "4 zł",
  },

  {
    id: 6,
    zadruk: "składanie rysunków powierzonych",
    a2: "2,00 zł",
    a1: "3,50 zł",
    a0: "6,50 zł",
    b2: "3,50 zł",
    b1: "8,50 zł",
  },
]

export const drukFoto = [
  {
    id: 1,
    papier: "Rodzaj\npapieru",
    papierGramatura: "",
    papierOpis: "",
    rozwijanie: false,
    a2: "A2",
    a1: "a1",
    a0: "a0",
    b2: "b2",
    b1: "b1",
    a2label: "420x594 mm\n<30%  —  50%  —  >70%",
    a1label: "594x841 mm\n<30%  —  50%  —  >70%",
    a0label: "841x1189 mm\n<30%  —  50%  —  >70%",
    b2label: "500x700 mm\n<30%  —  50%  —  >70%",
    b1label: "700x1000 mm\n<30%  —  50%  —  >70%",
  },

  {
    id: 2,
    papier: "Powlekany",
    papierGramatura: "120 g/m²",
    papierOpis:
      "Wysokiej jakości papier jednostronnie powlekany o gramaturze 120 g/m2, matowy o bardzo gładkiej powierzchni. Idealnie nadaje się do druku plakatów oraz materiałów reklamowych. Zapewnia doskonałe odwzorowanie barw oraz dokładność druku wszelkich detali. ",
    rozwijanie: true,
    a2: 12,
    a1: 23,
    a0: 47,
    b2: 14,
    b1: 28,
  },

  {
    id: 3,
    papier: "Powlekany",
    papierGramatura: "180 g/m²",
    papierOpis:
      "Wysokiej jakości papier jednostronnie powlekany o gramaturze 180 g/m2, matowy o bardzo gładkiej powierzchni. Idealnie nadaje się do druku plakatów oraz materiałów reklamowych. Zapewnia doskonałe odwzorowanie barw oraz dokładność druku wszelkich detali. ",
    rozwijanie: true,
    a2: 13,
    a1: 26,
    a0: 52,
    b2: 17,
    b1: 34,
  },

  {
    id: 4,
    papier: "EXTRA matt",
    papierGramatura: "190 g/m²",
    papierOpis:
      "Wysokiej jakości papier jednostronnie powlekany, matowy o bardzo gładkiej powierzchni i podwyższonym stopniu białości. Idealnie nadaje się do druku plakatów oraz materiałów reklamowych. Zapewnia doskonałe odwzorowanie barw oraz dokładność druku wszelkich detali.",
    rozwijanie: true,
    a2: 15,
    a1: 29,
    a0: 57,
    b2: 20,
    b1: 39,
  },

  {
    id: 5,
    papier: "Polipropylen",
    papierGramatura: "150 g/m²",
    papierOpis:
      "Wysokiej jakości papier syntetyczny polipropylenowy o wykończeniu matowym. Ma niezwykle gładką powierzchnię i całkowitą wytrzymałość na uszkodzenia mechaniczne (rozdarcie) oraz warunki atmosferyczne. Idealnie nadaje się do druku plakatów oraz materiałów reklamowych. Zapewnia doskonałe odwzorowanie barw oraz dokładność druku wszelkich detali.",
    rozwijanie: true,
    a2: 20,
    a1: 40,
    a0: 78,
    b2: 26,
    b1: 52,
  },

  {
    id: 6,
    papier: "satyna / gloss",
    papierGramatura: "235 g/m²",
    papierOpis:
      "Wysokiej jakości papier fotograficzny jednostronnie powlekany o wykończeniu błyszczącym lub satynowym. Doskonale nadaje się do druku zdjęć, plakatów oraz materiałów reklamowych. Zapewnia doskonałe odwzorowanie barw, głębię koloru oraz dokładność druku wszelkich detali.",
    rozwijanie: true,
    a2: 20,
    a1: 40,
    a0: 78,
    b2: 26,
    b1: 52,
  },

  {
    id: 7,
    papier: "extra satyna / gloss",
    papierGramatura: "290 g/m²",
    papierOpis:
      "Wysokiej jakości papier fotograficzny jednostronnie powlekany o wykończeniu błyszczącym, satynowym lub perłowym o podwyższonym stopniu białości. Doskonale nadaje się do druku zdjęć, plakatów oraz materiałów reklamowych. Zapewnia doskonałe odwzorowanie barw, głębię koloru oraz dokładność druku wszelkich detali.",
    rozwijanie: true,
    a2: 22,
    a1: 44,
    a0: 88,
    b2: 31,
    b1: 62,
  },

  {
    id: 8,
    papier: "płótno syntetyczne",
    papierGramatura: "220 g/m²",
    papierOpis:
      "Płótno syntetyczne polipropylenowe jest doskonałą, tańszą alternatywą dla droższego płótna naturalnego. Pozwala na wydruk wysokiej jakości reprodukcji oraz zdjęć, które można naciągnąć na krosna malarskie. Charakteryzuje się dużą wytrzymałością, elastycznością oraz łatwością obróbki.",
    rozwijanie: true,
    a2: 24,
    a1: 47,
    a0: 94,
    b2: 34,
    b1: 68,
  },

  {
    id: 9,
    papier: "płótno bawełniane",
    papierGramatura: "350 g/m²",
    papierOpis:
      "Wysokiej jakości płótno naturalne, wykonane w 100% z bawełny, jest doskonałe do wydruku doskonałej jakości reprodukcji i zdjęć, które można naciągnąć na krosna malarskie. Charakteryzuje się dużą wytrzymałością, elastycznością oraz łatwością obróbki.",
    rozwijanie: true,
    a2: 31,
    a1: 62,
    a0: 96,
    b2: 47,
    b1: 93,
  },

  {
    id: 10,
    papier: "folia samoprzylepna",
    papierGramatura: "200 g/m²",
    papierOpis:
      "Do wyboru wykończenie matowe lub błyszczące.\nFolie pokryte od spodu klejem pozwalają na perfekcyjne przyleganie wydruku do podłoża. Druk na foliach gloss i matt wygląda identycznie jak na papierze fotograficznym, dlatego podłoża te doskonale nadają się do druku plakatów, zdjęć, wystaw.",
    rozwijanie: true,
    a2: 21,
    a1: 42,
    a0: 84,
    b2: 27,
    b1: 55,
  },

  {
    id: 11,
    papier: "kalka techniczna",
    papierGramatura: "90 g/m²",
    papierOpis:
      "Rodzaj półprzeźroczystego papieru, stosowany do sporządzania rysunków technicznych, cechuje się dobrą wytrzymałością, równą i gładką powierzchnią oraz odpornością na wymazywanie i skrobanie korekcyjne. Kalka techniczna absorbuje wilgoć z powietrza atmosferycznego, w wyniku czego ulega marszczeniu.",
    rozwijanie: true,
    a2: 10,
    a1: 21,
    a0: 42,
    b2: 13,
    b1: 26,
  },
]

export const papier = [
  {
    id: 1,
    papier: "Rodzaj\npapieru",
    papierGramatura: "",
    rozwijanie: false,
    papierOpis: "",
    a4: "A4",
    a3: "a3",
    sra3: "sra3",
    a4label: "210x297 mm",
    a3label: "297x420 mm",
    sra3label: "320x450 mm",
  },

  {
    id: 2,
    papier: "Powlekany",
    papierGramatura: "100 g/m²",
    papierOpis:
      "Wysokiej jakości papier dwustronnie powlekany o gramaturze 100g/m2. Charakteryzuje się wysoką gładkością, a jego nieprzejrzystość pozwala na druk obustronny. Zawiera włókna eukaliptusa podnoszące sztywność papieru. Doskonale nadaje się do druku zdjęć, plakatów oraz materiałów reklamowych. ",
    rozwijanie: true,
    a4: "0,20 zł",
    a3: "0,35 zł",
    sra3: "—",
  },

  {
    id: 3,
    papier: "Powlekany",
    papierGramatura: "120 g/m²",
    papierOpis:
      "Wysokiej jakości papier dwustronnie powlekany o gramaturze 120g/m2. Charakteryzuje się wysoką gładkością, a jego nieprzejrzystość pozwala na druk obustronny. Zawiera włókna eukaliptusa podnoszące sztywność papieru. Doskonale nadaje się do druku zdjęć, plakatów oraz materiałów reklamowych.",
    rozwijanie: true,
    a4: "0,25 zł",
    a3: "0,45 zł",
    sra3: "—",
  },

  {
    id: 4,
    papier: "Powlekany",
    papierGramatura: "160 g/m²",
    papierOpis:
      "Wysokiej jakości papier dwustronnie powlekany o gramaturze 160g/m2. Charakteryzuje się wysoką gładkością, a jego nieprzejrzystość pozwala na druk obustronny. Zawiera włókna eukaliptusa podnoszące sztywność papieru. Doskonale nadaje się do druku zdjęć, plakatów oraz materiałów reklamowych.",
    rozwijanie: true,
    a4: "0,40 zł",
    a3: "0,75 zł",
    sra3: "—",
  },

  {
    id: 5,
    papier: "Powlekany",
    papierGramatura: "200 g/m²",
    papierOpis:
      "Wysokiej jakości papier dwustronnie powlekany o gramaturze 200g/m2. Charakteryzuje się wysoką gładkością, a jego nieprzejrzystość pozwala na druk obustronny. Zawiera włókna eukaliptusa podnoszące sztywność papieru. Doskonale nadaje się do druku zdjęć, plakatów oraz materiałów reklamowych.",
    rozwijanie: true,
    a4: "0,50 zł",
    a3: "0,95 zł",
    sra3: "—",
  },

  {
    id: 6,
    papier: "Powlekany",
    papierGramatura: "250 g/m²",
    papierOpis:
      "Wysokiej jakości papier dwustronnie powlekany o gramaturze 250g/m2. Charakteryzuje się wysoką gładkością, a jego nieprzejrzystość pozwala na druk obustronny. Zawiera włókna eukaliptusa podnoszące sztywność papieru. Doskonale nadaje się do druku zdjęć, plakatów oraz materiałów reklamowych.",
    rozwijanie: true,
    a4: "0,65 zł",
    a3: "1,25 zł",
    sra3: "—",
  },

  {
    id: 7,
    papier: "Powlekany",
    papierGramatura: "300 g/m²",
    papierOpis:
      "Wysokiej jakości papier dwustronnie powlekany o gramaturze 300g/m2. Charakteryzuje się wysoką gładkością, a jego nieprzejrzystość pozwala na druk obustronny. Zawiera włókna eukaliptusa podnoszące sztywność papieru. Doskonale nadaje się do druku zdjęć, plakatów oraz materiałów reklamowych.",
    rozwijanie: true,
    a4: "0,80 zł",
    a3: "1,55 zł",
    sra3: "—",
  },

  {
    id: 8,
    papier: "Powlekany gloss",
    papierGramatura: "170 g/m²",
    papierOpis:
      "Dwustronnie powlekany papier do kolorowego druku laserowego o unikalnym błyszczącym wykończeniu (gramatura papieru - 170g/m2). Charakteryzuje się wysoką gładkością, a nieprzejrzystość gwarantuje możliwość zadruku obustronnego. Doskonale nadaje się do druku zdjęć, plakatów oraz materiałów reklamowych. Zapewnia doskonałe odwzorowanie barw, głębię koloru oraz dokładność druku wszelkich detali",
    rozwijanie: true,
    a4: "0,70 zł",
    a3: "1,30 zł",
    sra3: "—",
  },

  {
    id: 9,
    papier: "Powlekany gloss",
    papierGramatura: "250 g/m²",
    papierOpis:
      "Dwustronnie powlekany papier do kolorowego druku laserowego o unikalnym błyszczącym wykończeniu (gramatura papieru - 250g/m2). Charakteryzuje się wysoką gładkością, a nieprzejrzystość gwarantuje możliwość zadruku obustronnego. Doskonale nadaje się do druku zdjęć, plakatów oraz materiałów reklamowych. Zapewnia doskonałe odwzorowanie barw, głębię koloru oraz dokładność druku wszelkich detali",
    rozwijanie: true,
    a4: "0,85 zł",
    a3: "1,60  zł",
    sra3: "—",
  },

  {
    id: 10,
    papier: "Samoprzylepny",
    papierGramatura: "naklejka 120 g/m²",
    papierOpis:
      "Papier pokryty z jednej strony warstwą kleju, zabezpieczoną odklejaną folią. Doskonały do druku naklejek, wlepek, cenówek, etykiet itp. Możliwy jest druk zarówno kolorowy, jak i czarno-biały. Dostępny w wykończeniu matowym i błyszczącym.",
    rozwijanie: true,
    a4: "1,50 zł",
    a3: "3,00 zł",
    sra3: "4,00 zł",
  },
]

export const klejenieT = [
  {
    id: 1,
    podklad: "Rozmiar\ni wykończenie",
    wykonczenie: "",
    t2: "T2",
    t25: "t2,5",
    t3: "t3",
    t2label: "tektura 2mm",
    t25label: "tektura 2,5mm",
    t3label: "tektura 3mm",
  },

  {
    id: 2,
    podklad: "B1/A1",
    wykonczenie: "matt",
    t2: "62 zł",
    t25: "64 zł",
    t3: "66 zł",
  },

  {
    id: 3,
    podklad: "B2/A2",
    wykonczenie: "matt",
    t2: "39 zł",
    t25: "40 zł",
    t3: "42 zł",
  },
]

export const klejenieP = [
  {
    id: 1,
    podklad: "Rozmiar\ni wykończenie",
    wykonczenie: "",
    p3: "P3",
    p5: "P5",
    pcw: "pcw",
    p3label: "pianka 3mm",
    p5label: "pianka 5mm",
    pcwlabel: "PCW spienione 3mm",
  },

  {
    id: 2,
    podklad: "B1/A1",
    wykonczenie: "matt",
    p3: "78 zł",
    p5: "84 zł",
    pcw: "—",
  },

  {
    id: 3,
    podklad: "B2/A2",
    wykonczenie: "matt",
    p3: "51 zł",
    p5: "53 zł",
    pcw: "—",
  },
]

export const laminowanie = [
  {
    id: 1,
    podklad: "Laminowanie",
    wykonczenie: "",
    a4: "A4",
    a3: "A3",
    b2: "B2/A2",
    b1: "B1/A1",
  },

  {
    id: 2,
    podklad: "laminat",
    wykonczenie: "gloss",
    a4: "2,50 zł",
    a3: "5,00 zł",
    b2: "10,00 zł",
    b1: "20,00 zł",
  },

  {
    id: 3,
    podklad: "laminat",
    wykonczenie: "matt",
    a4: "3,50 zł",
    a3: "6,50 zł",
    b2: "—",
    b1: "—",
  },
]

export const oprawa = [
  {
    id: 1,
    oprawa: "Rodzaj\noprawy",
    wykonczenie: "",
    rozmiary: "",
    a4: "A4",
    a3: "A3",
    a4label: "210 x 297mm",
    a3label: "297 x 420mm",
  },

  {
    id: 2,
    oprawa: "oprawa z listwą",
    wykonczenie: "plastikowa listwa \nfolia przeźroczysta\ntył: czarny karton ",
    rozmiary: "3mm\n6mm\n10mm\n15mm",
    a4: "4,00 zł",
    a3: "6,00 zł",
  },

  {
    id: 3,
    oprawa: "bindowanie",
    wykonczenie: "plastikowa binda \nfolia przeźroczysta\ntył: czarny karton ",
    rozmiary: "1-65 k\n66-150 k\n151-200 k\n>200 k",
    a4: "6 zł\n7 zł\n8 zł\n10 zł",
    a3: "8 zł\n9 zł\n10 zł\n12 zł",
  },

  {
    id: 4,
    oprawa: "bindowanie\nspiralą",
    wykonczenie: "plastikowa spirala\nfolia przeźroczysta\ntył: czarny karton ",
    rozmiary: "1-65 k\n66-150 k\n151-200 k\n>200 k",
    a4: "6 zł\n7 zł\n8 zł",
    a3: "8 zł\n9 zł\n10 zł",
  },

  {
    id: 5,
    oprawa: "termobindowanie",
    wykonczenie: "plastikowa spirala\nfolia przeźroczysta\ntył: czarny karton ",
    rozmiary: "<40 k\n41-80 k\n81-150 k",
    a4: "8 zł\n9 zł\n10 zł",
    a3: "—",
  },

  {
    id: 6,
    oprawa: "wpięcie do\nskoroszytu",
    wykonczenie: "plastikowa binda \nfolia przeźroczysta\ntył: czarny karton ",
    rozmiary: "",
    a4: "4,00 zł",
    a3: "—",
  },
]

export const inne = [
  {
    id: 1,
    usluga: "Rodzaj\nusługi",
    uslugalabel: "",
    cenauslugi: "cena",
  },

  {
    id: 2,
    usluga: "Zszywanie",
    uslugalabel: "zszywaczem długoramiennym",
    cenauslugi: "1 zł",
    cenauslugilabel: "1 zszycie",
  },

  {
    id: 3,
    usluga: "Zszywanie",
    uslugalabel: "zszywaczem profesjonalnym",
    cenauslugi: "1 zł",
    cenauslugilabel: "1 zszycie",
  },

  {
    id: 4,
    usluga: "Bigowanie",
    uslugalabel: "tworzenie rowka i zgięcie papieru",
    cenauslugi: "0,50 zł",
    cenauslugilabel: "1 zgięcie",
  },

  {
    id: 5,
    usluga: "Przygotowanie plików\ndo druku",
    uslugalabel: "np. układanie na arkuszu",
    cenauslugi: "5,00 zł",
    cenauslugilabel: "",
  },

  {
    id: 6,
    usluga: "Szybka edycja",
    uslugalabel: "tekst",
    cenauslugi: "4,00 zł",
    cenauslugilabel: "",
  },

  {
    id: 7,
    usluga: "szybka edycja",
    uslugalabel: "grafika",
    cenauslugi: "7,00 zł",
    cenauslugilabel: "",
  },

  {
    id: 8,
    usluga: "szybka edycja",
    uslugalabel: "tekst + grafika",
    cenauslugi: "10,00 zł",
    cenauslugilabel: "",
  },
]
